import React, {  useEffect, useState } from 'react'
import Button from '../../ui/Button'
import Loader from '../../ui/Loader'
import DetailsItem from './DetailsItem'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import { businessInfoToUpdate, companyInfoToUpdate } from '../../../../utils/helperFunctions/businessInfoToUpdate'
import { useUpdateApplicationDetailsMutation } from '../../../hooks/useApplicationDetailsQuery'

const EditApplicationDetailsModal = React.lazy(() => import('../../modals/applications/EditApplicationDetailsModal'))

const omitFromDisplay = [
    'identificationDocumentUrl', 'IdentificationDocumentUrl', 'companyAddress',
    'passportPhotographUrl', 'postalAddress',
    'residentialAddress', 'signatureUrl',
    'SignatureUrl', 'IdentificationDocumentUrl',
    'PassportPhotographUrl',
    'BusinessAddress', 'fileNameUrlPairs',
    'businessAddress', 'financialRecordKeeperAddress',
    'trademarkClassifications', 'representationUrl',
    'passportDataPageUrl', 'certificateUrl',
    'companyRegistrationCertificateUrl',
    'birthCertificateUrl', 'nonCriminalRecordDocumentUrl',
    'proprietorInformation', 'BankAccountOpeningDetails',
    'ProprietorInformation', 'PostalAddress',
    'ResidentialAddress', 'CompanyProfile',
    'DirectorInformation', 'SignatoryInformation',
    'UtilityBillUrl', 'ReferrFormUrl',
    'proprietorIDUrl', 'businessInformation',
    'countryTrademarkClassifications',
    'proficiencyCertificateUrl',
]


const nestedInformation = [
    'postalAddress', 'residentialAddress',
    'PostalAddress', 'ResidentialAddress',
    'BusinessAddress', 'companyAddress',
    'businessAddress', 'financialRecordKeeperAddress',
]

function DetailsCard({ title, cardData, applicationDetails, nested, edit }:any) {
    
    const [cardDataState, setcardDataState] = useState(cardData)
    const [openEditModal, setopenEditModal] = useState<boolean>(false)
    const [sectionData, setsectionData] = useState<any[]>([])
    const [searchParams] = useSearchParams()


    useEffect(() => {
        setcardDataState(cardData)
    }, [cardData])
    

    const handleOpenEditModal = (section:string, data:any, key:string) => {
        if(!edit){
            return
        }
        setsectionData([ section, data, key ])
        setopenEditModal(!openEditModal)
    }

    const updateApplicationDetailsMutation = useUpdateApplicationDetailsMutation({})

    const handleUpdateApplicationDetails = () => {
        let data = applicationDetails
        if(title === 'Company Information'){
            data = companyInfoToUpdate({ data: data, updatedData: cardDataState})
        }
        
        if(title === 'Business Information'){
            data = businessInfoToUpdate(applicationDetails, cardDataState, data)
        }
        if(title === 'Proprietor Information'){
            if(data.proprietorInformation){
                data = {
                    ...data,
                    proprietorInformation: {
                        ...data.proprietorInformation,
                        soleProprietor: cardDataState
                    }
                }
            }
            if(data.ProprietorInformation){
                data = {
                    ...data,
                    ProprietorInformation: {
                        ...data.ProprietorInformation,
                        SoleProprietor: cardDataState
                    }
                }
            }
        }

        if(!searchParams.get('companyId')){
            toast.warning("Company Id is not available", {
                toastId: "company Id unavailable"
            });
            return
        }
   
        updateApplicationDetailsMutation.mutate(data)
    }

    const displayDeciders = {
        omitFromDisplay,
        nestedInformation
    }

    const itemInfo = {
        title, 
        edit
    }

    return (
        <>
            <EditApplicationDetailsModal applicationDetails={applicationDetails} cardData={cardDataState} setcardData={(data:any) => setcardDataState(data)} sectionData={sectionData} open={openEditModal} onClose={() => setopenEditModal(false)}/>
            <div className={`${edit ? 'border rounded-md px-5 md:px-10 pb-10 my-5' : 'border-t '} border-lightGray`}>
                <div className={`flex flex-col xl:flex-row lg:w-full justify-between gap-10 xl:gap-0   py-10`}>
                    <div className='lg:w-2/5'>
                        <h3 className='font-semibold'>{ title }</h3>
                        <p className='text-gray'>Use this to file the application</p>
                    </div>
                    <div className=' lg:w-3/5'>
                        <div className='grid md:grid-cols-2 gap-5'>
                            <DetailsItem displayDeciders={displayDeciders} nested={nested} handleOpenEditModal={handleOpenEditModal} itemInfo={itemInfo} cardDataState={cardDataState}/>
                        </div>
                    </div>
                </div>
                {edit && <div className='flex justify-end'>
                    <Button className='' onClick={handleUpdateApplicationDetails}>{updateApplicationDetailsMutation.isLoading ? <Loader color='stroke-white'/> : `Update ${ title }`}</Button>
                </div>}
            </div>
        </> 
    )
}

export default DetailsCard